
 /* {
  margin:0;
  padding: 0;
  box-sizing: border-box;
} */

nav {
  background-color: #282c34;
  position: absolute;
  top:0;
  left: 0;
  padding: 10px 0;;
  text-align: right;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

nav li {
  margin: 0 15px;
}

nav a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: color 0.3s;
}

nav a:hover {
  color: orangered;
}

.farm-image {
  width: 100%;
  height: auto;
}

body {
  font-family: Arial, sans-serif;
  background-color: goldenrod;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
}



.header {
  /* background-color: rgba(0, 0, 0, 0.7); */
 background-color: green;
  color:#f0f0f0;
 
  padding: 20px;
  text-align: center;
}

.header h1 {
  font-size: 50px;
  margin-bottom: 10px;

}

.content {
  display: flex;
 flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
  gap: 20px;
}

.management-system {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 800px;
  width: 100%;
  text-align: center;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: green;
}

.available-sessions {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  padding: 20px;
  flex-basis: 28%;
}

.available-sessions h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.session-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.session-item button {
  background-color: #61dafb;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.claim-button {
  background-color: #61dafb;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.claim-button:hover {
  background-color: orangered;
}
.claim-button.claimed {
  background-color: red;
}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: green;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* responsive styles */
@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .available-sessions {
    padding: 10px;
  }
  
}